import React, {useRef, useEffect} from 'react';
import styled from "styled-components";
import DefaultImage from "../../assets/image/noimage.jpg";
import DOMPurify from 'dompurify'

const ImageModal = ({fileUrl, comment, onClose}) => {
    const modalRef = useRef(null);
    const handleClose = () => {
        onClose?.();
    };
    const imageErrorHandler = (e) => {
        e.target.src = DefaultImage
    }
    const sanitizedDescription = (description) => {
        return DOMPurify.sanitize(description);
    }

    //스크롤 막기
    // useEffect(() => {
    //     const $body = document.querySelector("body");
    //     const overflow = $body.style.overflow;
    //     $body.style.overflow = "hidden";
    //     return () => {
    //         $body.style.overflow = overflow
    //     };
    // }, []);

    // 외부 클릭 시 닫힘
    useEffect(() => {
        const handleClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                handleClose();
            }
        };
        window.addEventListener("mousedown", handleClick);
        return () => window.removeEventListener("mousedown", handleClick);
    }, []);

    return (
        <Overlay>
            <ModalWrap ref={modalRef}>
                <div
                    style={{width:"400px",height:"400px",alignItems:"center",justifyContent:"center",display:"flex"}}
                >
                    <img
                        style={{maxWidth: "100%", maxHeight: "100%", objectFit: "contain"}}
                        src={fileUrl}
                        alt="artwork Image"
                        // height="400" width="400"
                        onError={imageErrorHandler}/>
                </div>

                {comment &&
                    <div className="text-left p-xl-3 bg-white" style={{width:"400px",height:"auto",alignItems:"center",justifyContent:"center",display:"flex"}}
                         dangerouslySetInnerHTML={{__html: sanitizedDescription(comment)}}>
                    </div>}

            </ModalWrap>
        </Overlay>
    );

}


const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
`;

const ModalWrap = styled.div`
    width: fit-content;
    height: fit-content;
    //background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
`;

export default ImageModal;